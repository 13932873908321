@use 'breakpoints';
@use 'colors';

.modal-component-portal {
  position: relative;
  z-index: 999;

  .ReactModal__Overlay {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7) !important;
    display: flex;
    justify-content: center;

    .modal-component-container {
      align-items: center;
      background: colors.$color-primary-90;
      border-radius: 2rem;
      display: flex;
      height: fit-content;
      justify-content: center;
      width: 100%;
    }
  }
}
