@use 'colors';
@use 'fonts';

.input-wrapper-component {
  &__input {
    .text-input {
      @include fonts.font-text-6;

      background-color: inherit;
      border: none;
      height: 100%;
      width: 100%;

      &:focus {
        color: colors.$color-primary-0;
        outline: none;

        &::placeholder {
          color: transparent;
        }
      }
    }

    .button-clear-input {
      cursor: pointer;
    }
  }
}

// trick to avoid "-internal-autofill-selected" style to be applied
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 600000s 0s,
    color 600000s 0s;
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//   -webkit-background-clip: text;
//   box-shadow: inset 0 0 20px 20px #23232329;
//   -webkit-text-fill-color: #ffffff;
//   transition: background-color 5000s ease-in-out 0s;
// }
