@use 'breakpoints';
@use 'colors';

.dialog-portal {
  .ReactModal__Overlay {
    .dialog-modal {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
      min-width: 28rem;
      overflow-y: scroll;
      padding: 4rem 3.5rem 2.5rem;

      @media screen and (min-width: breakpoints.$breakpoint-tablet) {
        height: fit-content;
        max-width: 40rem;
      }

      &__main {
        max-width: 40rem;

        &__actions {
          display: flex;
          flex-direction: column;
          row-gap: 2.5rem;
        }
      }
    }
  }
}
