@use 'colors';
@use 'fonts';

.header-component {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  row-gap: 2rem;

  &__title {
    color: colors.$color-primary-0;
    font-size: fonts.$font-size-xl;
    font-weight: fonts.$font-weight-xl;
    line-height: 108%;
    text-align: center;
  }

  &__subtitle {
    color: colors.$color-primary-0;
    font-size: fonts.$font-size-s;
    font-weight: fonts.$font-weight-md;
    line-height: 140%;
    text-align: center;

    &--emphasized {
      font-weight: fonts.$font-weight-xl;
    }
  }
}
