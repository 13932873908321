@use 'colors';
@use 'fonts';

.input-wrapper-component {
  color: colors.$color-primary-65;
  width: 100%;

  &__label {
    color: colors.$color-primary-0;
    display: inline-block;
    font-size: 1.6rem;
    font-weight: fonts.$font-weight-lg;
    line-height: 2.2rem;
    margin-bottom: 1rem;
  }

  &__input {
    align-items: center;
    background-color: colors.$system-gray-06-dark;
    border: 1px solid colors.$system-gray-06-dark;
    border-radius: 1rem;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    display: flex;
    height: 5.3rem;
    overflow: hidden;
    padding-left: 2rem;
    padding-right: 1.5rem;

    .left-icon {
      margin-right: 2rem;
    }

    input {
      background-color: inherit;
      text-overflow: ellipsis;

      &::placeholder {
        color: rgba(255, 255, 255, 0.3);
      }
    }

    .icon-wrapper {
      align-items: center;
      column-gap: 1rem;
      display: flex;
      height: 100%;

      &.right-icon {
        cursor: pointer;

        .icon {
          &:hover {
            color: colors.$color-primary-0;
          }
        }
      }

      .icon {
        color: colors.$color-primary-65;
        font-size: fonts.$font-size-lg;
      }
    }
  }

  &__error {
    color: colors.$color-error-70;
    font-size: 1.4rem;
    font-weight: fonts.$font-weight-md;
    line-height: 1.7rem;
    margin-top: 0.6rem;

    .custom-email-validation-error-message {
      padding: 0.5rem 0;
      text-align: center;

      .underlined {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &:hover,
  &.pseudoclass--hover {
    &:not(.active) {
      .input-wrapper-component__input {
        &:not(.disabled) {
          border: 1px solid rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  &.active,
  &.pseudoclass--active {
    color: colors.$color-primary-0;

    .input-wrapper-component__input {
      border: 1px solid colors.$color-primary-0;
    }
  }

  &--error {
    .input-wrapper-component__input {
      &:not(.disabled) {
        border-color: colors.$color-error-70;
      }
    }
  }

  &--valid {
    .input-wrapper-component__input {
      &:not(.disabled) {
        border-color: colors.$system-green-dark;
      }
    }
  }
}
