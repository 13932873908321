@use 'colors';
@use 'fonts';

:root {
  --toastify-color-success: #{colors.$color-success-70};
  --toastify-text-color-success: #{colors.$color-primary-0};
  --toastify-color-error: #{colors.$color-error-70};
  --toastify-text-color-error: #{colors.$color-primary-0};
  --toastify-toast-min-height: 5.95rem;

  .Toastify {
    margin: 0;
    padding: 0;

    * {
      margin: 0;
      padding: 0;
    }

    &__toast-container {
      max-width: 59.5rem;
      min-width: 28rem;
      width: fit-content;

      &--bottom-center {
        bottom: 11.5rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &__toast {
      border-radius: 1.4rem;
      font-family: inherit;
      padding: 1rem 8rem 1rem 4rem;

      .toast-component {
        align-items: center;
        column-gap: 0.8rem;
        display: flex;

        &__message__link {
          cursor: pointer;
          font-weight: fonts.$font-weight-lg;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &__close-button {
      position: absolute;
      right: 1rem;
    }
  }
}
