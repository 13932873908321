@use 'breakpoints';
@use 'colors';
@use 'fonts';

@import url(https://use.typekit.net/dst1oag.css); // Lato Adobe font

$font-path: '/fonts/';

@font-face {
  font-family: SFPro;
  font-weight: fonts.$font-weight-xs;
  src: url('#{$font-path}/sf-pro/SF-Pro-Display-Light.otf') format('opentype');
}
@font-face {
  font-family: SFPro;
  font-weight: fonts.$font-weight-s;
  src: url('#{$font-path}/sf-pro/SF-Pro-Display-Regular.otf') format('opentype');
}
@font-face {
  font-family: SFPro;
  font-weight: fonts.$font-weight-md;
  src: url('#{$font-path}/sf-pro/SF-Pro-Display-Medium.otf') format('opentype');
}
@font-face {
  font-family: SFPro;
  font-weight: fonts.$font-weight-lg;
  src: url('#{$font-path}/sf-pro/SF-Pro-Display-Semibold.otf') format('opentype');
}
@font-face {
  font-family: SFPro;
  font-weight: fonts.$font-weight-xl;
  src: url('#{$font-path}/sf-pro/SF-Pro-Display-Bold.otf') format('opentype');
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;

  body {
    background-color: colors.$color-primary-90;
    font-family: SFPro;
    font-size: 1.6rem;
    margin: 0;
    padding: 0;

    * {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    *::placeholder {
      opacity: 1;
    }

    pre {
      background-color: #282a36 !important;
    }
  }

  a {
    color: colors.$color-primary-0;
    text-decoration: none;
  }

  button {
    align-items: center;
    background: transparent;
    border: none;
    display: flex;
    justify-content: center;
  }

  /* Misc */

  .flex {
    display: flex;
  }

  .flex.column {
    flex-direction: column;
  }

  .justify-center {
    justify-content: center;
  }

  .center {
    text-align: center;
  }
}

.error-boundary-container {
  align-items: center;
  background-color: colors.$color-primary-90;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  .error-boundary-fallback {
    color: colors.$color-primary-0;

    .sorry {
      font-size: fonts.$font-size-4xl;
    }

    .link {
      font-size: fonts.$font-size-lg;

      a {
        color: colors.$color-primary-0;
        text-decoration: underline;
      }
    }
  }
}

// Skeletons

.darkui-shimmer {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #50535a;
  background-image: linear-gradient(to right, #50535a 0%, #656871 20%, #50535a 40%, #50535a 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  height: 104px;
  position: relative;
}

.darkui-shimmer div {
  background: #313236;
  height: 6px;
  left: 0;
  position: absolute;
  right: 0;
}

.highcontrast-shimmer {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #737373;
  background-image: linear-gradient(to right, #737373 0%, #454545 20%, #737373 40%, #737373 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  height: 104px;
  position: relative;
}

.highcontrast-shimmer div {
  background: #bdbdbd;
  height: 6px;
  left: 0;
  position: absolute;
  right: 0;
}

.Toastify__toast-theme--colored {
  &.Toastify__toast--success {
    border-radius: 1.4rem;
  }

  &.Toastify__toast--error {
    border-radius: 1.4rem;
  }

  .Toastify__toast-body {
    font-size: 1.9rem;
    font-weight: fonts.$font-weight-lg;
    justify-content: center;
    line-height: 2.8rem;
    padding-left: 4rem;
    padding-right: 5rem;
  }
}

.Toastify__toast-container {
  width: fit-content !important;

  &--bottom-center {
    margin-bottom: 0.5rem;
  }

  &--top-center {
    margin-top: 10.2rem;
  }
}

.Toastify__close-button {
  color: #{colors.$color-primary-0} !important;
}

.Toastify__toast-container--bottom-center {
  bottom: 12rem !important;
}
